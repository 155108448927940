import React, { useContext, useEffect, useReducer, useState } from "react";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";
import { Badge, Collapse, List } from "@material-ui/core";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import ContactPhoneOutlinedIcon from "@material-ui/icons/ContactPhoneOutlined";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import CodeRoundedIcon from "@material-ui/icons/CodeRounded";
import EventIcon from "@material-ui/icons/Event";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PeopleIcon from "@material-ui/icons/People";
import ListIcon from "@material-ui/icons/ListAlt";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import ForumIcon from "@material-ui/icons/Forum";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import BusinessIcon from "@material-ui/icons/Business";
import StarOutlineIcon from "@material-ui/icons/StarOutline";
import { AddToQueueRounded } from "@material-ui/icons";
import { CalendarToday, LoyaltyRounded } from "@material-ui/icons";
import MailOutlineIcon from "@material-ui/icons/MailOutline"; // Importe o ícone de e-mail
import SendIcon from "@material-ui/icons/Send"; // Importe o ícone de enviar
import ScheduleIcon from "@material-ui/icons/Schedule";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import Typography from "@material-ui/core/Typography";

import { i18n } from "../translate/i18n";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { AuthContext } from "../context/Auth/AuthContext";
import { Can } from "../components/Can";
import { socketConnection } from "../services/socket";
import { isArray } from "lodash";
import api from "../services/api";
import toastError from "../errors/toastError";
import usePlans from "../hooks/usePlans";
import useVersion from "../hooks/useVersion";
import RotateRight from "@material-ui/icons/RotateRight";
import logo from "../assets/logo.png";

import {
    PieChart,
    MessageCircle,
    KanbanSquare,
    MessagesSquare,
    Bookmark,
    HelpCircle,
    ListChecks,
    Users,
    CalendarPlus,
    Zap,
    TrendingUp,
    Settings2,
    Contact,
    ListTodo,
    BookOpen,
    MonitorCheck,
    Workflow,
    UserPlus,
    PlugZap,
    Landmark,
    Settings,
} from "lucide-react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    ListSubheader: {
        height: 26,
        marginTop: "-15px",
        marginBottom: "20px",
    },
    listItem: {
        borderRadius: "0.5rem",
        margin: "5px",
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: "#fff",
        },
        "&.active": {
            backgroundColor: "red",
        },
    },
    subMenu: {
        paddingLeft: 15,
        borderLeft: `2px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
    },
    logoutButton: {
        borderRadius: 10,
        marginTop: 10,
        backgroundColor: theme.palette.error.main,
        color: "rgb(52, 71, 103)",
        "&:hover": {
            backgroundColor: theme.palette.error.dark,
        },
    },
    active: {
        backgroundColor: "rgb(255, 255, 255)",
        boxShadow: "rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem",
        color: "rgb(52, 71, 103)",
        borderRadius: "0.5rem",
        fontWeight: "600 !important",
        marginTop: "5px",
        marginBottom: "5px",
    },
    icon: {
        boxShadow: "rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem",
        backgroundColor: "rgb()255, 255, 255)",
        borderRadius: "0.5rem",
        padding: 5,
        width: "30px",
        height: "30px",
        /* paddingLeft: "10px",
        paddingTop: "10px",
        Bottom: "10px", */
    },


}));

function ListItemLink(props) {
    const { icon, primary, to, className, onClick } = props;

    const renderLink = React.useMemo(
        () =>
            React.forwardRef((itemProps, ref) => (
                <RouterLink to={to} ref={ref} {...itemProps} />
            )),
        [to]
    );

    return (
        <li>
            <ListItem dense button component={renderLink} onClick={onClick} className={className}>
                {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
                <ListItemText primary={primary} />
            </ListItem>
        </li>
    );
}

const reducer = (state, action) => {
    if (action.type === "LOAD_CHATS") {
        const chats = action.payload;
        const newChats = [];

        if (isArray(chats)) {
            chats.forEach((chat) => {
                const chatIndex = state.findIndex((u) => u.id === chat.id);
                if (chatIndex !== -1) {
                    state[chatIndex] = chat;
                } else {
                    newChats.push(chat);
                }
            });
        }

        return [...state, ...newChats];
    }

    if (action.type === "UPDATE_CHATS") {
        const chat = action.payload;
        const chatIndex = state.findIndex((u) => u.id === chat.id);

        if (chatIndex !== -1) {
            state[chatIndex] = chat;
            return [...state];
        } else {
            return [chat, ...state];
        }
    }

    if (action.type === "DELETE_CHAT") {
        const chatId = action.payload;

        const chatIndex = state.findIndex((u) => u.id === chatId);
        if (chatIndex !== -1) {
            state.splice(chatIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }

    if (action.type === "CHANGE_CHAT") {
        const changedChats = state.map((chat) => {
            if (chat.id === action.payload.chat.id) {
                return action.payload.chat;
            }
            return chat;
        });
        return changedChats;
    }
};

const MainListItems = (props, { collapsed }) => {
    const classes = useStyles();
    const { drawerClose } = props;
    const { whatsApps } = useContext(WhatsAppsContext);
    const { user, handleLogout } = useContext(AuthContext);

    const [connectionWarning, setConnectionWarning] = useState(false);
    const [showCampaigns, setShowCampaigns] = useState(false);
    // novas features
    const [showSchedules, setShowSchedules] = useState(false);
    const [showInternalChat, setShowInternalChat] = useState(false);
    const [showExternalApi, setShowExternalApi] = useState(false);



    const [openKanbanSubmenu, setOpenKanbanSubmenu] = useState(false);
    const [openComunicSubmenu, setOpenComunicSubmenu] = useState(false);
    const [openGestSubmenu, setOpenGestSubmenu] = useState(false);
    const [openAdminSubmenu, setOpenAdminSubmenu] = useState(false);
    const [openEmailSubmenu, setOpenEmailSubmenu] = useState(false);
    const [openCampaignSubmenu, setOpenCampaignSubmenu] = useState(false);

    const handleSubmenuClick = (submenu) => {
        setOpenComunicSubmenu(submenu === 'comunic' ? !openComunicSubmenu : false);
        setOpenGestSubmenu(submenu === 'gest' ? !openGestSubmenu : false);
        setOpenAdminSubmenu(submenu === 'admin' ? !openAdminSubmenu : false);
        setOpenEmailSubmenu(submenu === 'email' ? !openEmailSubmenu : false);
        setOpenCampaignSubmenu(submenu === 'campaign' ? !openCampaignSubmenu : false);
    };


    const history = useHistory();
    const location = useLocation();

    const [invisible, setInvisible] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchParam] = useState("");
    const [chats, dispatch] = useReducer(reducer, []);
    const [version, setVersion] = useState(false);

    const { getPlanCompany } = usePlans();

    const { getVersion } = useVersion();

    const handleClickLogout = () => {
        //handleCloseMenu();
        handleLogout();
    };

    useEffect(() => {
        async function fetchVersion() {
            const _version = await getVersion();
            setVersion(_version.version);
        }
        fetchVersion();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);
    }, [searchParam]);

    useEffect(() => {
        async function fetchData() {
            const companyId = localStorage.getItem("companyId");
            const planConfigs = await getPlanCompany(undefined, companyId);
            setShowCampaigns(planConfigs.plan.useCampaigns);
            setShowSchedules(planConfigs.plan.useSchedules);
            setShowInternalChat(planConfigs.plan.useInternalChat);
            setShowExternalApi(planConfigs.plan.useExternalApi);
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            fetchChats();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParam, pageNumber]);

    useEffect(() => {
        const companyId = localStorage.getItem("companyId");
        const socket = socketConnection({ companyId });

        socket.on(`company-${companyId}-chat`, (data) => {
            if (data.action === "new-message") {
                dispatch({ type: "CHANGE_CHAT", payload: data });
            }
            if (data.action === "update") {
                dispatch({ type: "CHANGE_CHAT", payload: data });
            }
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    useEffect(() => {
        let unreadsCount = 0;
        if (chats.length > 0) {
            for (let chat of chats) {
                for (let chatUser of chat.users) {
                    if (chatUser.userId === user.id) {
                        unreadsCount += chatUser.unreads;
                    }
                }
            }
        }
        if (unreadsCount > 0) {
            setInvisible(false);
        } else {
            setInvisible(true);
        }
    }, [chats, user.id]);

    // useEffect(() => {
    //   if (localStorage.getItem("cshow")) {
    //     setShowCampaigns(true);
    //   }
    // }, []);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (whatsApps.length > 0) {
                const offlineWhats = whatsApps.filter((whats) => {
                    return (
                        whats.status === "qrcode" ||
                        whats.status === "PAIRING" ||
                        whats.status === "DISCONNECTED" ||
                        whats.status === "TIMEOUT" ||
                        whats.status === "OPENING"
                    );
                });
                if (offlineWhats.length > 0) {
                    setConnectionWarning(true);
                } else {
                    setConnectionWarning(false);
                }
            }
        }, 2000);
        return () => clearTimeout(delayDebounceFn);
    }, [whatsApps]);

    const fetchChats = async () => {
        try {
            const { data } = await api.get("/chats/", {
                params: { searchParam, pageNumber },
            });
            dispatch({ type: "LOAD_CHATS", payload: data.records });
        } catch (err) {
            toastError(err);
        }
    };

    // Defina o estado para controlar a rota atual
    const [currentPath, setCurrentPath] = useState("");

    useEffect(() => {
        // Atualize o estado da rota atual sempre que a localização mudar
        setCurrentPath(location.pathname);
    }, [location.pathname]);

    // Função para verificar se a rota atual corresponde à rota do item de menu
    const isActiveRoute = (path) => {
        return currentPath === path;
    };

    return (
        <div /* onClick={drawerClose} */ style={{ marginTop: "40px" }}>
            {/* DASHBOARD */}
            <Can role={user.profile} perform="dashboard:view" yes={() => (<ListItemLink onClick={drawerClose} to="/" primary="Dashboard" icon={<PieChart />} className={isActiveRoute("/") ? classes.active : ""} />)} />

            {/* COMUNICAÇÃO */}
            <ListItem dense button onClick={() => handleSubmenuClick('comunic')}>
                <ListItemIcon>
                    <MessageCircle />
                </ListItemIcon>
                <ListItemText primary={i18n.t("Comunicação")} />
                {openComunicSubmenu ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
            <Collapse style={{ paddingLeft: 15 }} in={openComunicSubmenu} timeout="auto" unmountOnExit >
                <List dense component="div" disablePadding>
                    <ListItem onClick={() => { history.push("/tickets"); drawerClose(); }} button className={isActiveRoute("/tickets") ? classes.active : ""}>
                        <ListItemIcon>
                            <WhatsAppIcon />
                        </ListItemIcon>
                        <ListItemText primary={i18n.t("Conversas")} />
                    </ListItem>
                    <ListItem onClick={() => { history.push("/kanban"); drawerClose(); }} button className={isActiveRoute("/kanban") ? classes.active : ""}>
                        <ListItemIcon>
                            <ListIcon />
                        </ListItemIcon>
                        <ListItemText primary={i18n.t("Kanban")} />
                    </ListItem>
                    <ListItem onClick={() => { history.push("/tagsKanban"); drawerClose(); }} button className={isActiveRoute("/tagsKanban") ? classes.active : ""}>
                        <ListItemIcon>
                            <CalendarToday />
                        </ListItemIcon>
                        <ListItemText primary={i18n.t("Etiquetas Kanban")} />
                    </ListItem>
                    <ListItemLink onClick={drawerClose} to="/quick-messages" primary={i18n.t("Respostas rápidas")} icon={<Zap />} className={isActiveRoute("/quick-messages") ? classes.active : ""} />
                    <ListItemLink onClick={drawerClose} to="/contacts" primary={i18n.t("mainDrawer.listItems.contacts")} icon={<Users />} className={isActiveRoute("/contacts") ? classes.active : ""} />
                    {/* <ListItem onClick={() => history.push("/campanhas")} button className={isActiveRoute("/tagsKanban") ? classes.active : ""}>
                        <ListItemIcon>
                            <EventAvailableIcon />
                        </ListItemIcon>
                        <ListItemText primary={i18n.t("Em Andamento")} />
                    </ListItem> */}
                </List>
            </Collapse>

            {/* GESTÃO DE TAREFAS */}
            <ListItem dense button onClick={() => handleSubmenuClick('gest')}>
                <ListItemIcon>
                    <PlaylistAddCheckIcon />
                </ListItemIcon>
                <ListItemText primary={i18n.t("Gestão de tarefas")} />
                {openGestSubmenu ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
            <Collapse style={{ paddingLeft: 15 }} in={openGestSubmenu} timeout="auto" unmountOnExit >
                <List dense component="div" disablePadding>
                    <ListItemLink onClick={drawerClose} to="/todolist" primary={i18n.t("Tarefas")} icon={<ListChecks />} className={isActiveRoute("/todolist") ? classes.active : ""} />
                    {showSchedules && (
                        <>
                            <ListItemLink onClick={drawerClose} to="/schedules" primary={i18n.t("mainDrawer.listItems.schedules")} icon={<CalendarPlus />} className={isActiveRoute("/schedules") ? classes.active : ""} />
                        </>
                    )}
                    <ListItemLink onClick={drawerClose} to="/tags" primary={i18n.t("Etiquetas")} icon={<Bookmark />} className={isActiveRoute("/tags") ? classes.active : ""} />

                </List>
            </Collapse>

            {/* Configurações */}
            <ListItem dense button onClick={() => handleSubmenuClick('admin')}>
                <ListItemIcon>
                    <Settings />
                </ListItemIcon>
                <ListItemText primary={i18n.t("Administração")} />
                {openAdminSubmenu ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
            <Collapse style={{ paddingLeft: 15 }} in={openAdminSubmenu} timeout="auto" unmountOnExit >
                <List dense component="div" disablePadding>
                    {showExternalApi && (
                        <>
                            <ListItemLink onClick={drawerClose} to="/messages-api" primary={i18n.t("mainDrawer.listItems.messagesAPI")} icon={<PlugZap />} className={isActiveRoute("/messages-api") ? classes.active : ""} />
                        </>
                    )}
                    <ListItemLink onClick={drawerClose} to="/users" primary={i18n.t("Usuários")} icon={<UserPlus />} className={isActiveRoute("/users") ? classes.active : ""} />
                    <ListItemLink onClick={drawerClose} to="/queues" primary={i18n.t("Departamentos e Bots")} icon={<Workflow />} className={isActiveRoute("/queues") ? classes.active : ""} />
                    <ListItemLink onClick={drawerClose} to="/connections" primary={i18n.t("Conexões")} icon={
                        <Badge badgeContent={connectionWarning ? "!" : 0} color="error" overlap="rectangular">
                            <MonitorCheck />
                        </Badge>
                    } className={isActiveRoute("/connections") ? classes.active : ""}
                    />
                    <ListItemLink onClick={drawerClose} to="/financeiro" primary={i18n.t("mainDrawer.listItems.financeiro")} icon={<Landmark />} className={isActiveRoute("/financeiro") ? classes.active : ""} />
                    <ListItemLink onClick={drawerClose} to="/settings" primary={i18n.t("Configurações")} icon={<Settings />} className={isActiveRoute("/settings") ? classes.active : ""} />
                    {user.super && (
                        <ListItemLink onClick={drawerClose} to="/companies" primary={i18n.t("Empresas")} icon={<BusinessIcon />} className={isActiveRoute("/companies") ? classes.active : ""} />
                    )}
                </List>
            </Collapse>

            {/* Campanhas e Marketing */}
            {showCampaigns && (
                <>
                    <ListItem dense button onClick={() => handleSubmenuClick('campaign')}>
                        <ListItemIcon>
                            <TrendingUp />
                        </ListItemIcon>
                        <ListItemText primary={i18n.t("Campanhas")} />
                        {openCampaignSubmenu ? (
                            <ExpandLessIcon />
                        ) : (
                            <ExpandMoreIcon />
                        )}
                    </ListItem>
                    <Collapse style={{ paddingLeft: 15 }} in={openCampaignSubmenu} timeout="auto" unmountOnExit>
                        <List dense component="div" disablePadding>
                            <ListItem onClick={() => { history.push("/campaigns"); drawerClose() }} button className={isActiveRoute("/campaigns") ? classes.active : ""}>
                                <ListItemIcon>
                                    <ListTodo />
                                </ListItemIcon>
                                <ListItemText primary="Listagem" />
                            </ListItem>
                            <ListItem onClick={() => { history.push("/contact-lists"); drawerClose() }} button className={isActiveRoute("/contact-lists") ? classes.active : ""}>
                                <ListItemIcon>
                                    <Contact />
                                </ListItemIcon>
                                <ListItemText primary="Listas de Contatos" />
                            </ListItem>
                            <ListItem onClick={() => { history.push("/campaigns-config"); drawerClose() }} button className={isActiveRoute("/campaigns-config") ? classes.active : ""}>
                                <ListItemIcon>
                                    <Settings2 />
                                </ListItemIcon>
                                <ListItemText primary="Configurações" />
                            </ListItem>
                        </List>
                    </Collapse>
                </>
            )}

            {/* Campanha e email */}
            <ListItem dense button /* onClick={() => setOpenEmailSubmenu((prev) => !prev)} */ onClick={() => handleSubmenuClick('email')}>
                <ListItemIcon>
                    <MailOutlineIcon />
                </ListItemIcon>
                <ListItemText primary={i18n.t("Email")} />
                {openEmailSubmenu ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
            <Collapse style={{ paddingLeft: 15 }} in={openEmailSubmenu} timeout="auto" unmountOnExit>
                <List dense component="div" disablePadding>
                    <ListItem onClick={() => { history.push("/Email"); drawerClose() }} button className={isActiveRoute("/Email") ? classes.active : ""}>
                        <ListItemIcon>
                            <SendIcon />
                        </ListItemIcon>
                        <ListItemText primary={i18n.t("Enviar")} />
                    </ListItem>
                    <ListItem onClick={() => { history.push("/EmailLis"); drawerClose() }} button className={isActiveRoute("/EmailLis") ? classes.active : ""}>
                        <ListItemIcon>
                            <EventIcon />
                        </ListItemIcon>
                        <ListItemText primary={i18n.t("Enviados")} />
                    </ListItem>

                    <ListItem onClick={() => { history.push("/EmailScheduler"); drawerClose() }} button className={isActiveRoute("/EmailScheduler") ? classes.active : ""}>
                        <ListItemIcon>
                            <ScheduleIcon />
                        </ListItemIcon>
                        <ListItemText primary={i18n.t("Agendar")} />
                    </ListItem>

                    <ListItem onClick={() => { history.push("/EmailsAgendado"); drawerClose() }} button className={isActiveRoute("/EmailsAgendado") ? classes.active : ""}>
                        <ListItemIcon>
                            <ScheduleIcon />
                        </ListItemIcon>
                        <ListItemText primary={i18n.t("Agendados")} />{" "}

                    </ListItem>
                </List>
            </Collapse>




            {/* <ListItemLink to="/tickets" primary={i18n.t("Conversas")} icon={<MessageCircle />} className={isActiveRoute("/tickets") ? classes.active : ""} /> */}


            {/* <ListItem dense button onClick={() => setOpenKanbanSubmenu((prev) => !prev)}>
                <ListItemIcon>
                    <KanbanSquare />
                </ListItemIcon>
                <ListItemText primary={i18n.t("Kanban")} />
                {openKanbanSubmenu ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
            <Collapse style={{ paddingLeft: 15 }} in={openKanbanSubmenu} timeout="auto" unmountOnExit >
                <List dense component="div" disablePadding>
                    <ListItem onClick={() => history.push("/kanban")} button className={isActiveRoute("/kanban") ? classes.active : ""}>
                        <ListItemIcon>
                            <ListIcon />
                        </ListItemIcon>
                        <ListItemText primary={i18n.t("Quadro")} />
                    </ListItem>
                    <ListItem onClick={() => history.push("/tagsKanban")} button className={isActiveRoute("/tagsKanban") ? classes.active : ""}>
                        <ListItemIcon>
                            <CalendarToday />
                        </ListItemIcon>
                        <ListItemText primary={i18n.t("Etiquetas")} />
                    </ListItem>
                    <ListItem onClick={() => history.push("/campanhas")} button className={isActiveRoute("/tagsKanban") ? classes.active : ""}>
                        <ListItemIcon>
                            <EventAvailableIcon />
                        </ListItemIcon>
                        <ListItemText primary={i18n.t("Em Andamento")} />
                    </ListItem>
                </List>
            </Collapse> */}

            {/* <ListItemLink to="/Calendario" primary={i18n.t("Calendario")} icon={<CalendarToday />} /> */}





            {showInternalChat && (
                <>
                    <ListItemLink to="/chats" primary={i18n.t("mainDrawer.listItems.chats")} icon={
                        <Badge color="secondary" variant="dot" invisible={invisible} overlap="rectangular" >
                            <MessagesSquare />
                        </Badge>
                    } className={isActiveRoute("/chats") ? classes.active : ""}
                    />
                </>
            )}

            {/*<ListItemLink
        to="/ChatGPT"
        primary={i18n.t("ChatGPT")}
        icon={<LiveHelpIcon />}
          />*/}



            <Can role={user.profile} perform="drawer-admin-items:view" yes={() => (
                <>

                    {/*<ListSubheader inset>
              {i18n.t("mainDrawer.listItems.administration")}
            </ListSubheader>*/ }


                    {user.super && (
                        <ListItemLink onClick={drawerClose} to="/announcements" primary={i18n.t("Informativos")} icon={<BookOpen />} className={isActiveRoute("/announcements") ? classes.active : ""} />
                    )}
                    {/*user.super && (
              <ListItemLink
                to="/campanhaAvancada"
                primary={i18n.t("Campanha Avancada")}
                icon={<PlaylistAddCheckIcon />}
              />
            )*/}



                    {/*<ListItemLink
              to="/ratings"
              primary={i18n.t("mainDrawer.listItems.ratings")}
              icon={<StarOutlineIcon />}
            /> 

            {
              <ListItemLink
                to="/integrations"
                primary={"Integrações"}
                icon={<AddToQueueRounded />}
              />
            }*/}


                    {/* {user.super && (
              <ListSubheader inset>
                {i18n.t("mainDrawer.listItems.administration")}
              </ListSubheader>
            )} */}



                    {!collapsed && (
                        <React.Fragment>

                            {/* <Hidden only={['sm', 'xs']}>
                                <img style={{ width: "100%", padding: "10px" }} src={logo} alt="image" />
                            </Hidden> */}

                            <ListItemLink onClick={drawerClose} to="/helps" primary={i18n.t("Ajuda")} icon={<HelpCircle />} className={isActiveRoute("/helps") ? classes.active : ""} />
                        </React.Fragment>
                    )}
                </>
            )}
            />
            {/* <Divider />
            <li>
                <ListItem button dense onClick={handleClickLogout} className={classes.logoutButton}>
                    <ListItemIcon>
                        <RotateRight />
                    </ListItemIcon>
                    <ListItemText primary={i18n.t("Sair")} />
                </ListItem>
            </li> */}
        </div>
    );
};

export default MainListItems;
